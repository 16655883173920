import Vue from 'vue'

const state = {
  pubid: '',
  pub1: '',
  pub2: '',
  pub3: '',
  pub4: '',
  pub5: '',
  pubid_reference: '',
}

const getters = {}

const actions = {
  setTrackingCookies({ state }) {
    Vue.saverium.setSalusCookie(state)
  },
  async fetchTrackingCookies({ commit }) {
    const response = await Vue.saverium.getSalusCookies()
    return response
  },
}

const mutations = {
  updateTrackers(state, payload) {
    state.pubid = payload.pubid
    state.pub1 = payload.pub1
    state.pub2 = payload.pub2
    state.pub3 = payload.pub3
    state.pub4 = payload.pub4
    state.pub5 = payload.pub5
    state.pubid_reference = payload.pubid_reference
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
