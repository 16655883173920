const state = {
  articles: [
    {
      title: 'Billigere lån online? Digitale banker er også kommet til Danmark',
      description:
        'Rimelige renter og fleksible lånevilkår er i stigende grad årsagen til, at en dansker vælger et lån, der tilbydes af en digital bank. Efterhånden som konkurrencen stiger på lånemarkedet, bliver sammenligningen af banker og lånetilbud vigtigere.',
      src: '/static/img/saverium_dk/article-imgs/hand-money.jpg',
      tiny: '/static/img/saverium_dk/article-imgs/hand-money-tiny.jpg',
      url: '/artikler/billigere-lan-online/',
      date: '14.09.2020',
    },
    {
      title: 'Ny lov begrænser renten på lån - Samle dine gamle lån',
      description:
        'En ny lov, der begrænser renten på lån trådte i kraft i Danmark her til sommer. Den nye lovgivning gælder ikke automatisk på gamle lån, så hvis du har optaget et lån inden 1. Juli 2020, skal du tage skridtet imod at erstatte dine gamle dyre lån med et lån, der falder ind under den nye lovgivning.',
      src: '/static/img/saverium_dk/article-imgs/money-finger.jpg',
      tiny: '/static/img/saverium_dk/article-imgs/money-finger-tiny.jpg',
      url: '/artikler/ny-lov-begraenser-renten-pa-lan-samle-dine-gamle-lan/',
      date: '14.09.2020',
    },
    {
      title:
        '“At havne i en farlig cirkel af gæld kan ske for enhver” - Læs Anne-Katrins historie om at tage kontrol over sin økonomi',
      description:
        'Anne-Katrin, 34, delte sin historie med os om at finde vej ud af gældsbyrden og den lettelse, som samlelån, bragte overblik over hendes økonomi. I denne artikel har vi samlet historien om Anne-Katrin og de bedste tips til at overtage dine egne finanser og kombinere lån.',
      src: '/static/img/saverium_dk/article-imgs/anne-katrin.jpg',
      tiny: '/static/img/saverium_dk/article-imgs/anne-katrin-tiny.webp',
      url: '/artikler/tage-kontrol-over-din-okonomi/',
      date: '14.09.2020',
    },
    {
      title:
        'Hvor meget kan jeg få udbetalt ved at optage et lån? – Og er det en god ide?',
      description:
        'Hvor mange penge du kan låne når du ansøger om det online, afhænger meget af hvor du ansøger om lånet, til hvilket formål og hvilket beløb du selv har tænkt på forhånd. Lånets størrelse bestemmes af de banker og finansieringsselskaber, der tilbyder lånene.',
      src: '/static/img/saverium_dk/article-imgs/sad-couple.jpg',
      tiny: '/static/img/saverium_dk/article-imgs/sad-couple-tiny.jpg',
      url: '/artikler/har-du-brug-for-et-lan/',
      date: '06.10.2020',
    },
    {
      title: 'Bedre økonomiske beslutninger - Foretag ikke disse fejl igen',
      description:
        'Hvordan får man familiens økonomi på benene igen? Bedre økonomiske beslutninger ligger kun bag et par små ændringer. Disse tip hjælper dig lettere med at slippe af med høje renter og dårlige beslutninger.',
      src: '/static/img/saverium_dk/article-imgs/better-financial-descisions.jpg',
      tiny: '/static/img/saverium_dk/article-imgs/better-financial-descisions-tiny.jpg',
      url: '/artikler/bedre-okonomiske-beslutninger-foretag-ikke-disse-fejl-igen/',
      date: '19.11.2020',
    },
    {
      title: 'Kreditværdighed - Disse 5 ting påvirker, om du får et lån',
      description:
        'Påvirker din kreditværdighed din låneansøgning? Læs her 5 grunde til, at du muligvis ikke får et lån fra en bank og hvordan du kan ordne det.',
      src: '/static/img/saverium_dk/article-imgs/creditworthiness.jpg',
      tiny: '/static/img/saverium_dk/article-imgs/creditworthiness-tiny.jpg',
      url: '/artikler/kreditvaerdighed-disse-5-ting-pavirker-om-du-far-et-lan/',
      date: '19.11.2020',
    },
    {
      title:
        'Kan jeg kombinere nogle af mine lån? - Hvorfor delvis betaling af gæld er det hele værd',
      description:
        ' Fik du ikke det lånetilbud, du ønskede? Det er også værd at udnytte et mindre lånetilbud til at kombinere gamle lån. Læs her for tip til delvis lånekonsolidering.',
      src: '/static/img/saverium_dk/article-imgs/how-can-i-combine-loan.jpg',
      tiny: '/static/img/saverium_dk/article-imgs/how-can-i-combine-loan-tiny.jpeg',
      date: '3.9.2021',
      url: '/artikler/kan-jeg-kombinere-nogle-af-mine-lan-hvorfor-delvis-betaling-af-gaeld-er-det-hele-vaerd/',
    },
    {
      title: 'At sammenligne hjælper os med at træffe det rette valg',
      description:
        'I dag sammenligner vi stort set alt der har en pris. Om det så er mælk nede ved den lokale købmand eller om det er et billigt billån man leder efter. Så vil vores mål altid være at finde det vi ønsker, men til den bedste pris som muligt.',
      src: '/static/img/saverium_dk/article-imgs/compare-everything.jpg',
      tiny: '/static/img/saverium_dk/article-imgs/compare-everything-tiny.jpeg',
      date: '29.11.2021',
      url: '/artikler/at-sammenligne-hjaelper-os-med-at-traeffe-det-rette-valg/',
    },
    {
      title: 'Renovering af hus og hjem under pandemien',
      description:
        'Igennem 2021 har de danske byggemarkeder haft supertravlt under den globale pandemi, de har skulle følge med de mange danskers lyst til at begynde at renovere hus og hjem under pandemien.',
      src: '/static/img/saverium_dk/article-imgs/renovating-under-pandemic.jpg',
      tiny: '/static/img/saverium_dk/article-imgs/renovating-under-pandemic-tiny.jpeg',
      date: '14.1.2022',
      url: '/artikler/renovering-af-hus-og-hjem-under-pandemien/',
    },
    {
      title: 'Hvor nemt er det at optage et lån?',
      description:
        'Det er nemmere end nogensinde før at finde det billigste lån online, så du har ingen grund til at vælge det første og måske bedste tilbud du ser, bare fordi det skal være nemt og hurtigt.',
      src: '/static/img/saverium_dk/article-imgs/how-easy-taking-out-loan.jpg',
      tiny: '/static/img/saverium_dk/article-imgs/how-easy-taking-out-loan-tiny.jpeg',
      date: '02.02.2022',
      url: '/artikler/hvor-nemt-er-det-at-optage-et-lan/',
    },
    {
      title: 'Hvad skal dit rådighedsbeløb være i 2022?',
      description:
        'Hvis man ønsker at skabe et overblik over ens privatøkonomi, er ens rådighedsbeløb et fornuftigt sted at starte. Det vil kunne fortælle dig hvor du kan forbedre din privatøkonomi ved at lave nogle få udregninger.',
      src: '/static/img/saverium_dk/article-imgs/available-amount-in-2022.jpg',
      tiny: '/static/img/saverium_dk/article-imgs/available-amount-in-2022-tiny.jpeg',
      date: '02.02.2022',
      url: '/artikler/hvad-skal-dit-radighedsbelob-vaere-i-2022/',
    },
  ],
  landingHeaders: [
    {
      title: 'Lån penge',
      src: '/laan-penge/',
    },
    {
      title: 'Samlelån',
      src: '/laan-penge/samlelaan/',
    },
    {
      title: 'Lånesammenligning',
      src: '/laan-penge/sammenligning/',
    },
    {
      title: 'Forbrugslån',
      src: '/laan-penge/forbrugslaan/',
    },
    {
      title: 'Lånebeløb',
      src: '/laan-penge/beloeb/',
    },
    {
      title: 'Kviklån',
      src: '/laan-penge/kviklaan/',
    },
    {
      title: 'Låneudbydere',
      src: '/laan-penge/laaneudbydere/',
    },
    {
      title: 'Privatlån',
      src: '/laan-penge/privatlaan/',
    },
    {
      title: 'Online lån',
      src: '/laan-penge/online-laan/',
    },
    {
      title: 'Hurtiglån',
      src: '/laan-penge/hurtiglaan/',
    },
    {
      title: 'Billån',
      src: '/laan-penge/billaan/',
    },
    {
      title: 'Minilån',
      src: '/laan-penge/minilaan/',
    },
    {
      title: 'Kassekredit',
      src: '/laan-penge/kassekredit/',
    },
    {
      title: 'Erhvervslån',
      src: '/laan-penge/erhvervslaan/',
    },
    {
      title: 'Lån til alle',
      src: '/laan-penge/laan-til-alle/',
    },
    {
      title: 'Boliglån',
      src: '/laan-penge/bolig/',
    },
  ],
}
const getters = {}

export default {
  state,

  getters,
}
