import {
  findMatchAmount,
  findAmountByIndex,
  validAmountToText,
  findAmountByIndexSme,
} from '@/utils/utils'
import _ from 'lodash'
import applicationActions from '@/store/actions/applicationActions'

const mappings = {
  first_name: 'firstName',
  last_name: 'lastName',
  loan_purpose: 'loanPurpose',
  dwelling_date: 'dwellingDate',
  marital_status: 'civilstatus',
  dependant_ages: 'dependantAges',
  household_digital_media_expenses: 'householdDigitalMediaExpenses',
  household_heating_expenses: 'householdHeatingExpenses',
  household_insurance_expenses: 'householdInsuranceExpenses',
  household_transport_expenses: 'householdTransportExpenses',
  household_utilities_expenses: 'householdUtilitiesExpenses',
  household_transportation_expenses: 'householdTransportExpenses',
  monthly_gross_income: 'income_gross',
  monthly_net_income: 'income_net',
  monthly_other: 'other_income',
  household_discretionary_income: 'funds_available',
  dwelling_expenses: 'costOfLiving',
  account_number: 'accountNumber',
  opened_date: 'openedDate',
  employment_type: 'occupation',
  business_id: 'businessId',
  start: 'employedSince',
  dk_citizen: 'isDanish',
  expiry_date: 'expiryDate',
  foreigner_number: 'foreignerNumber',

  permit_number: 'permitNumber',
  subtype: 'subType',
  type: 'typeOfResidencePermit',
  unemployment_fund_member: 'unemploymentFund',
  unemployment_fund_member_since: 'memberOfUnemploymentFundSince',
  country_resident_since: 'countryResidentSince',
}
const coapplicantMappings = {
  email: 'email',
  employment_type: 'occupation',
  first_name: 'firstName',
  last_name: 'lastName',
  phone: 'phone',
  monthly_gross_income: 'monthlyGrossIncome',
  monthly_net_income: 'monthlyNetIncome',
  monthly_other: 'otherIncome',
  unemployment_fund_member: 'unemploymentFund',
  unemployment_fund_member_since: 'memberOfUnemploymentFundSince',
  new_debt_this_tax_year: 'newDebtThisYear',
  start: 'employedSince',
  business_id: 'businessId',
  dk_citizen: 'isDanish',
  expiry_date: 'expiryDate',
  foreigner_number: 'foreignerNumber',
  lived_in_denmark_since: 'countryResidentSince',
  permit_number: 'permitNumber',
  subtype: 'subType',
  type: 'typeOfResidencePermit',
  coapplicant_tos_accepted: 'coapplicantTos',
  coapplicant_bank_consents: 'coapplicantBankconsents',
}

const state = {
  currentStep: 0,

  maxStep: 0,
  requiredFields: [
    // Step 1
    ['email', 'phone', 'firstName', 'lastName', 'loanPurpose', 'tos'],
    // Step 2
    [
      'dwellingDate',
      'civilstatus',
      'dwelling',
      'street',
      'city',
      'postalCode',
      'occupation',
      'education',
      'employedSince',
    ],
    // Step 3
    [
      'income_gross',
      'income_net',
      'funds_available',
      'costOfLiving',
      'householdConsumption',
    ],
    // Step 4
    ['bankCode', 'accountNumber', 'personalDataPolicy', 'rki', 'ssn'],
    [
      'coapplicant.email',
      'coapplicant.occupation',
      'coapplicant.firstName',
      'coapplicant.lastName',
      'coapplicant.phone',
      'coapplicant.ssn',
      'coapplicant.employedSince',
      'coapplicantBankconsents',
      'coapplicantTos',
    ],
  ],
  email: '',
  phone: '',
  amount: '100000',
  education: '',
  amount_index: 49,
  repayment_time: '12',
  firstName: '',
  lastName: '',
  isDanish: true,
  countryResidentSince: '',
  costOfLiving: 0,
  street: '',
  postalCode: '',
  city: '',
  householdHeatingExpenses: 0,
  householdUtilitiesExpenses: 0,
  householdDigitalMediaExpenses: 0,
  householdInsuranceExpenses: 0,
  householdTransportExpenses: 0,
  dwelling: '',
  dwellingDate: '',
  coApplicant: false,
  civilstatus: '',
  idnumber: '',
  ssn: '',
  employedSince: '',
  income_gross: 0,
  income_net: 0,
  funds_available: 0,
  other_income: 0,
  occupation: '',
  unemploymentFund: false,
  issuer: 0,
  other_credit_card: false,
  creditCards: { visa: 0, masterCard: 0 },
  tos: 0,
  personalDataPolicy: false,
  marketing: false,
  applicationList: [],
  formValidationError: false,
  errors: {},
  stepErrors: [],
  sendingApplication: false,
  new_application: false,
  hasStudentloan: false,
  hasMortgage: false,
  hasCarloan: false,
  hasOtherloan: false,
  rki: '',
  leasingExpense: 0,
  existingLoans: {
    mortgage: {
      toBePaid: 0,
      payment: 0,
      consider_consolidation: 0,
      type: 2,
      lender: '',
    },
    studentloan: {
      toBePaid: 0,
      type: 10,
      payment: 0,
      consider_consolidation: 0,
      lender: '',
    },
    carloan: {
      toBePaid: 0,
      type: 4,
      payment: 0,
      consider_consolidation: 0,
      lender: '',
    },

    otherloan: {
      toBePaid: 0,
      type: 1,
      payment: 0,
      consider_consolidation: 0,
      lender: '',
    },
  },
  hasnewDebt: false,
  newDebtThisYear: 0,
  dependants: 0,
  dependantAges: [],
  cars: [],
  bankCode: '',
  accountNumber: '',
  loanPurpose: '',
  errorsInCheckbox: false,
  bank_account_opened: '',
  ownership: 0,
  numberOfLeasedCars: 0,
  leasing_expense: '',
  lastYearTotal: 0,
  businessId: '',
  otherSource: '',
  openedDate: '',
  accountType: '',
  subType: '',
  typeOfResidencePermit: '',
  foreignerNumber: '',
  expiryDate: '',
  permitNumber: '',
  residencePermit: [],
  memberOfUnemploymentFundSince: null,
  dataPolicyError: false,
  isBasis: '',
  eskatUrl: '',
  spouseIncome: 0,
  carTypes: '',
  carTypesError: false,
  carTypesNull: false,
  kidsAges: '',
  kidsAgesError: false,
  kidsAgesNull: false,
  hasOwnHouse: false,
  coapplicant: {
    firstName: '',
    lastName: '',
    ssn: '',
    isDanish: true,
    countryResidentSince: '',
    residencePermit: [],
    accountType: '',
    subType: '',
    typeOfResidencePermit: '',
    foreignerNumber: '',
    expiryDate: '',
    monthlyGrossIncome: 0,
    monthlyNetIncome: 0,
    newDebtThisYear: 0,
    phone: '',
    otherIncome: 0,
    unemploymentFund: false,
    memberOfUnemploymentFundSince: null,
    occupation: '',
    employedSince: '',
    businessId: '',
    email: '',

    dataPolicyError: false,
    tosErrors: false,
  },
  housingBenefit: false,
  monthlyPropertyTax: false,
  privacyPolicy: 0,
  coapplicantTos: false,
  coapplicantBankconsents: false,
  draftFound: true,
  loading: false,
  draftInProcess: false,
  costOfCars: 0,
  smeloan: {
    amount: '200000',
    repayment_time: '12',
    businessName: '',
    phone: '',
    contactEmail: '',
    businessId: '',
    streetAddress: '',
    postalCode: '',
    turnover: 0,
    yearFounded: '',
    firstName: '',
    lastName: '',
    ssn: '',
    accountNumber: '',
    accountingSystem: '',
    city: '',
    tos: false,
    registeringNumber: '',
    amount_index: '',
    marketing: false,
  },
  smeRequiredFields: ['businessName', 'phone', 'contactEmail', 'businessId'],
  smeErrors: {},
  householdConsumption: 0,
  shareOfCosts: 0,
  serverError: false,
  user: {
    token: null,
    applicationUUID: null,
    jwt_token: null,
  },
}

const getters = {
  checkErrors(state) {
    return Object.keys(state.errors).length
  },
  getNewApplication: state => state.new_application,
}

const actions = applicationActions

const mutations = {
  setLoading(state, value) {
    state['loading'] = value
  },
  sendApplication(state) {
    state['sendingApplication'] = true
  },
  sendNewApplication(state) {
    state['new_application'] = true
  },
  sendApplicationFinished(state) {
    state['sendingApplication'] = false
  },
  setApplicationList(state, applicationList) {
    state['applicationList'] = applicationList
  },
  updateSmeLoanAmount(state, { key, value }) {
    state.smeloan['amount'] = findAmountByIndexSme(value)
    _.set(state, key, value)
  },
  updateApplication(state, { key, value }) {
    // update label from amount
    if (key === 'smeloan.amount_index') {
      state.smeloan['amount'] = findAmountByIndexSme(value)
    }
    if (key === 'amount') {
      state['amount_label'] = validAmountToText(value)
    }
    // update amount if label changed
    if (key === 'amount_label') {
      state['amount'] = findMatchAmount(value)
    }

    if (key === 'amount_index') {
      state['amount'] = findAmountByIndex(value)
    }

    if (key === 'occupation') {
      const businessIdIndex = state.requiredFields[1].indexOf('businessId')
      if (value === '4' && businessIdIndex === -1) {
        state.requiredFields[1].push('businessId')
      } else {
        state.requiredFields[1].splice(businessIdIndex, 1)
      }
    }

    if (key === 'isDanish') {
      const inDenmarkIndex = state.requiredFields[3].indexOf('countryResidentSince')
      const residenceIndex = state.requiredFields[3].indexOf('foreignerNumber')
      const typeIndex = state.requiredFields[3].indexOf('typeOfResidencePermit')
      const permitIndex = state.requiredFields[3].indexOf('permitNumber')
      const subIndex = state.requiredFields[3].indexOf('subType')
      if (state['isDanish'] === false) {
        if (inDenmarkIndex === -1) {
          state.requiredFields[3].push('countryResidentSince')
        }
        if (residenceIndex === -1) {
          state.requiredFields[3].push('foreignerNumber')
        }
        if (typeIndex === -1) {
          state.requiredFields[3].push('typeOfResidencePermit')
        }
        if (permitIndex === -1) {
          state.requiredFields[3].push('permitNumber')
        }
        if (subIndex === -1) {
          state.requiredFields[3].push('subType')
        }
      } else {
        state.requiredFields[3].splice(inDenmarkIndex)
        state.requiredFields[3].splice(residenceIndex)
        state.requiredFields[3].splice(typeIndex)
        state.requiredFields[3].splice(permitIndex)
        state.requiredFields[3].splice(subIndex)
      }
    }

    if (key === 'coapplicant.occupation') {
      const businessIdIndex = state.requiredFields[4].indexOf('coapplicant.businessId')
      if (value === '4' && businessIdIndex === -1) {
        state.requiredFields[4].push('coapplicant.businessId')
      } else {
        state.requiredFields[4].splice(businessIdIndex, 1)
      }
    }
    if (key === 'cars') {
      const carIndex = state.requiredFields[1].indexOf('carTypes')
      const leasingIndex = state.requiredFields[2].indexOf('leasingExpense')
      const costIndex = state.requiredFields[2].indexOf('costOfCars')
      if (state['cars'].length > 0) {
        state.requiredFields[2].push('costOfCars')
        if (state['cars']?.some(car => car.type === null) && carIndex === -1) {
          state.requiredFields[1].push('carTypes')
          state['carTypesNull'] = true
        } else if (!state['cars'].some(car => car.type === null)) {
          state['carTypesNull'] = false
          state.requiredFields[1].splice(carIndex, 1)
        }
        if (state['cars'].some(car => car.type === 'Leaset') && leasingIndex === -1) {
          state.requiredFields[2].push('leasingExpense')
        } else if (!state['cars'].some(car => car.type === 'Leaset')) {
          state.requiredFields[2].splice(leasingIndex, 1)
        }
      } else {
        state.requiredFields[2].splice(costIndex, 1)
        state.requiredFields[1].splice(carIndex, 1)
        state.requiredFields[2].splice(leasingIndex, 1)
        state['carTypesNull'] = false
      }
    }
    // dependants are handled a bit different from cars, therefore monitoring both keys.
    if (key === 'dependants') {
      const kidIndex = state.requiredFields[1].indexOf('kidsAges')
      if (state['dependants'] > 0) {
        state['dependantAges'].forEach(kid => {
          if (kid === null && kidIndex === -1) {
            state.requiredFields[1].push('kidsAges')
            state['kidsAgesNull'] = true
          } else {
            state['kidsAgesNull'] = false
            state.requiredFields[1].splice(kidIndex)
          }
        })
      } else {
        state.requiredFields[1].splice(kidIndex)
        state['kidsAgesNull'] = false
      }
    }
    if (key === 'dependantAges') {
      const kidIndex = state.requiredFields[1].indexOf('kidsAges')
      state['dependantAges'].forEach(kid => {
        if (kid === null) {
          state.requiredFields[1].push('kidsAges')
          state['kidsAgesNull'] = true
        } else {
          state['kidsAgesNull'] = false
          state.requiredFields[1].splice(kidIndex)
        }
      })
    }
    if (key === 'rki') {
      const rkiIndex = state.requiredFields[3].indexOf('rki')
      if (rkiIndex !== -1) {
        state.requiredFields[3].splice(rkiIndex, 1)
      }
    }

    _.set(state, key, value)
  },
  updateCreditCards(state, { key, value }) {
    state.creditCards[key] = value
  },
  updateExistingLoans(state, { key, value, loan_type }) {
    state.existingLoans[loan_type][key] = value
  },
  setErrors(state, errors) {
    state.errors = errors
  },
  setStepErrors(state, errors) {
    state.stepErrors = errors
  },
  setSmeErrors(state, errors) {
    state.smeErrors = errors
  },

  setStepProgress(state, value) {
    state.currentStep = value
    state.maxStep = Math.max(value, state.maxStep)
  },

  fieldError(state, key) {
    state.errors = Object.assign({}, state.errors, { [key]: ['Invalid'] })
    if (key === 'postalCode') {
      state.errors = Object.assign({}, state.errors, { [key]: ['invalid_postal_code'] })
    }
  },
  removeError(state, key) {
    const errors = Object.assign({}, state.errors)
    delete errors[key]
    state.errors = errors
  },
  setCarError(state, value) {
    state.carTypesError = value
  },
  setKidError(state, value) {
    state.kidsAgesError = value
  },
  setApplication(state, data, key) {
    const applicantData = {
      ...data,
      ...data.applicants[0],
      ...data.applicants[0].address,
      ...data.applicants[0].employments[0],
      ...data.applicants[0].bank_account,
      ...data.applicants[0].residence_permit,
    }
    Object.keys(applicantData).forEach(key => {
      if (Object.keys(state).includes(key)) {
        state[key] = applicantData[key]
      } else if (Object.keys(mappings).includes(key)) {
        state[mappings[key]] = applicantData[key]
      }
    })
    if (data.applicants.length === 2) {
      state['coApplicant'] = true
      const coapplicantData = {
        ...data.applicants[1],
        ...data.applicants[1].employments[0],
        ...data.applicants[0].residence_permit,
      }
      Object.keys(coapplicantData).forEach(key => {
        if (Object.keys(coapplicantMappings).includes(key)) {
          state['coapplicant'][coapplicantMappings[key]] = coapplicantData[key]
        }
      })
    }
  },
  draftFound(state, value) {
    state['draftFound'] = value
  },
  setDraftProcess(state, value) {
    state['draftInProcess'] = value
  },
  setValue(state, { key, value }) {
    state[key] = value
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
