<template>
  <div id="app">
    <b-overlay
      id="overlay-background"
      :show="this.cookieConsent === false"
      variant="black"
      opacity="0.7"
      rounded="sm"
    >
      <Header />
      <div :class="mainPage ? '' : 'app-wrapper'">
        <router-view />
      </div>
      <CookiePopup
        v-if="this.cookieConsent === false"
        :consentUrl="$root.rn.PrivacyPolicyView"
      />
      <Footer />
    </b-overlay>
  </div>
</template>

<script>
import Footer from '@/components/saverium_dk/FooterComponent'
import Header from '@/components/saverium_dk/HeaderComponent'
import CookiePopup from '@/components/CookiePopup'
export default {
  name: 'App',
  computed: {
    mainPage() {
      return this.$route.path === '/'
    },
    cookieConsent() {
      return this.$store.state.user.cookieConsent
    },
  },
  components: {
    Header,
    Footer,
    CookiePopup,
  },
  created() {
    this.$store.dispatch('getEnums')
    this.$store.dispatch('checkCookieConsent')
    this.$store.dispatch('getVersionId')
  },
  mounted() {
    const version = process.env.VUE_APP_VERSION_TAG
    const built = process.env.VUE_APP_BUILD_TIME
    console.log(`Saverium Front version ${version} built ${built}`)
  },
}
</script>

<style lang="scss">
@import '@/assets/sass/app.scss';
@import '@/assets/sass/dk.scss';
</style>
