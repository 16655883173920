import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import VueAnalytics from 'vue-analytics'
import VueGtm from '@gtm-support/vue2-gtm'
import i18n from '@/lang'
import { Integrations } from '@sentry/tracing'
import App from '@/app/App.saverium_dk.vue'
import store from '@/store/saverium_dk'
import router from '@/router/saverium_dk'
import { routes } from '@/router/saverium_dk/routes'
import SaveriumAPI from '@/saverium/SaveriumApiClass'
import settings from '@/settings/settings.js'
import * as Sentry from '@sentry/vue'
import LottieAnimation from 'lottie-vuejs'
import Vuelidate from 'vuelidate'

const oidcSettings = require('../settings/settings').oidcSettings

Vue.use(BootstrapVue)
Vue.use(SaveriumAPI, { config: settings, oidcConfig: oidcSettings })
Vue.use(LottieAnimation)
Vue.use(Vuelidate)

// Load tracking/logging stuff if ID:s are defined
if (settings.SENTRY_DSN !== '') {
  Sentry.init({
    Vue,
    environment: settings.SENTRY_ENV,
    dsn: settings.SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['saverium.dk', 'stage.saverium.dk', /^\//],
        attachProps: true,
      }),
    ],
  })
}

if (settings.GOOGLE_ANALYTICS !== '') {
  Vue.use(VueAnalytics, {
    id: settings.GOOGLE_ANALYTICS,
    router,
  })
}

if (settings.GTM_ID !== '') {
  Vue.use(VueGtm, {
    id: settings.GTM_ID,
    vueRouter: router,
  })
}

const rn = routes[settings.LOCALE]

Vue.config.productionTip = false
/* eslint-disable no-new */
new Vue({
  el: '#app',
  data: {
    rn: rn.paths,
    brand: settings.BRAND,
  },
  router,
  store,
  i18n,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event('x-app-rendered')),
})
