<template>
  <footer class="d-flex flex-column footer-dk" id="footer">
    <div v-if="type === 'consumerloan'">
      <AboutUsWidget v-if="showFullFooter" />
    </div>
    <div v-if="type === 'companyloan'">
      <section
        id="information"
        class="flex-fill"
        style="padding-top: 2rem"
        v-if="showFullFooter"
      >
        <div class="container">
          <h3 class="title">
            Har din virksomhed brug for en udvidelse? - Et virksomhedslån til udvidelse af
            virksomhedens drift
          </h3>
          <p>
            En eksisterende, succesrig virksomhed vil på et eller andet tidspunkt have
            omkostninger, der kan være vanskelige at aktivere uden ordentlig finansiering.
            Hvis virksomheden klarer sig godt, har din virksomhed måske brug for en
            udvidelse eller det vil være en god ide at overveje at udvide den, så du kan
            opnå det fulde potentiale af dit firma Det kan være tid til at foretage
            investeringer, ansætte nye medarbejdere, udvide lageret eller måske flytte til
            et nyt kontor. Et virksomhedslån til udvidelse af virksomhedens drift kan være
            en god mulighed for dette, hvis det ellers er vanskeligt at få finansiering
            til udvidelsen. Selvfølgelig kan et virksomhedslån også bruges sammen med et
            sikret lån, der ansøges om fra en bank, for eksempel som sikkerhed eller
            udbetaling ved køb af nye forretningslokaler.
          </p>
          <p>
            At udvide en virksomhed kan medføre yderligere omkostninger, men hvis
            virksomheden fortsætter med at få succes, betaler ekspansionen sig normalt
            hurtigt. En virksomhed har brug for plads og finansiering for at vokse og
            vokse igen for at få succes. Konkurrencen mellem virksomheder er hård, og det
            er ofte risikabelt at prøve, selvom besparelserne er tænkt over. Nøglen til at
            prøve er at tør og implementere sin egen idé. Når alt kommer til alt, hvis
            forretningsidéen er god, og virksomheden driver sin forretning uden problemer
            og med hårdt arbejde, er grænsen for virksomhedens succes intet andet end
            himlen.
          </p>
        </div>
      </section>
      <section
        class="flex-fill"
        style="padding-top: 2rem; background: white; color: #05083c"
        v-if="showFullFooter"
      >
        <div class="container">
          <h3>
            Det er en god idé at sammenligne et erhvervslån – Husk at beregne dit
            virksomhedslån
          </h3>
          <p class="blue-text">
            Når du ansøger om et erhvervslån, er det værd at huske at overveje alle
            långivere på markedet og deres lånemuligheder. På grund af den hårde
            konkurrence på lånemarkedet er det en god idé at sammenligne et erhvervslån og
            til det kan du bruge en sammenligningstjeneste, der indhenter lånetilbud til
            dig fra forskellige låneudbydere så du kan beregne dit virksomhedslån et
            samlet sted. En mulighed er den gratis låneplatform Saverium.dk, som giver dig
            mulighed for hurtigt og nemt at finde det bedste erhvervslån til dig selv. Den
            gratis virksomhedslånberegner giver en indikation af de faktiske omkostninger
            ved lånet, som du skal overveje om, passer til din virksomheds situation.
          </p>
          <p class="blue-text">
            Ved at finde det billigste og bedste virksomhedslån til dig selv og dit firma,
            sparer du betydelige penge i lånets tilbagebetalingsomkostninger. Via
            Saverium.dk kan du ansøge om et selskabslån uden sikkerhed, let og
            overskueligt med en enkelt ansøgning. Saverium.dk tilbyder lån i mellem 10.000
            til 1.000.000 kr. og foreslår passende og præcise lånetilbud, der kan findes i
            henhold til kriterierne i din låneansøgning. Du kan vælge det bedst egnede
            virksomhedslån blandt alle de tilbudte lånetilbud og fokusere på at forbedre
            din virksomheds resultater.
          </p>
        </div>
      </section>
    </div>
    <div class="footer">
      <script type="application/javascript" id="salusgroupClickTrack"></script>

      <div class="container-fluid no-gutters d-flex">
        <div class="row col-12">
          <div class="col-12 col-md-4 customer-service-info">
            <span class="title">{{ $t('customer_service') }}</span>

            <span class="white">{{ $t('customer_service_text') }}</span
            ><span class="white"> kundeservice@saverium.dk</span><br />

            <span class="white">
              <br />
              Ved at logge ind på din
              <b-link :to="$root.rn.MyAccountView">Saverium-konto</b-link>, kan du se din
              låneansøgning samt dine lånetilbud.</span
            >

            <b-link :to="$root.rn.MainView"
              ><span class="title mt-4">Saverium.dk</span></b-link
            >
            <span style="color: white">
              LVS Brokers <br />
              CVR: FI25219658<br />
              Applebys Pl. 7<br />
              1411 København K<br />
              Danmark
            </span>
            <br />
            <span class="socials">
              <a
                href="https://www.facebook.com/saverium"
                target="_blank"
                rel="noopener"
                aria-label="facebook"
              >
                <i class="fab fa-facebook-f"></i>
              </a>
              <a
                href="https://instagram.com/saverium.dk"
                aria-label="instagram"
                target="_blank"
                rel="noopener"
              >
                <i class="fab fa-instagram"></i>
              </a>
            </span>
          </div>

          <div class="col-12 col-md-8 row link-row">
            <div>
              <ul class="nav" v-for="(article, index) in articles" :key="index">
                <li class="dk nav-item">
                  <b-link :to="article.src">{{ article.title }}</b-link>
                </li>
              </ul>
            </div>
            <div class="shortlist">
              <ul class="nav">
                <li class="dk nav-item">
                  <b-link :to="$root.rn.ArticleMenuView">{{ $t('articles') }}</b-link>
                </li>
                <li class="dk nav-item">
                  <b-link :to="$root.rn.TosView">{{ $t('Terms') }}</b-link>
                </li>
                <li class="dk nav-item">
                  <b-link :to="$root.rn.PrivacyPolicyView">{{
                    $t('Privacy_policy')
                  }}</b-link>
                </li>
                <li class="dk nav-item">
                  <b-link :to="$root.rn.SmeTosView">{{ $t('Sme_terms') }}</b-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import AboutUsWidget from '@/components/saverium_dk/seo_components/AboutUsWidget'
import salusClickTracker from '@/utils/saverium_dk/tracker'
import * as Cookies from 'tiny-cookie'
import { salusId } from '@/utils/saverium_dk/const'

export default {
  data() {
    return {}
  },
  mounted() {
    const referrer = Document.referrer

    // Salusgroup_click is of the following format:
    // {"clickid":"dk_xx","pubid":"2020","pubidsub1":"omae","pubidsub2":"wa","pubidsub3":"mou","pubidsub4":"shindeiru","pubidsub5":"nani??"}
    // First split the string by comma, it will leave values like this '"pubid":"3"'
    // Then remove all "" and return values after :
    const clickCookies = Cookies.get('salusgroup_click')
    const splitCookies = clickCookies?.split(',')

    const handledCookies = splitCookies?.map(
      cookie => cookie.replace(/[{"}]/g, '').split(':')[1]
    )

    // All hope abandon ye who enter here

    // Pubids are set with a priority:
    // If referrer, take values from referrer
    // If no referrer, check if there are search params and take values from that
    // If no search params, check the cookies if they exist already
    // If cookies do not exist, set the default value (pubid = 1547006)
    if (referrer) {
      const url = new URL(referrer)
      this.$store.commit('updateTrackers', {
        pubid: url.searchParams.get('pubid'),
        pub1: url.searchParams.get('pubidsub1'),
        pub2: url.searchParams.get('pubidsub2'),
        pub3: url.searchParams.get('pubidsub3'),
        pub4: url.searchParams.get('pubidsub4'),
        pub5: url.searchParams.get('pubidsub5'),
        pubid_reference: url.searchParams.get('pubid_reference'),
      })
    } else if (window.location.search) {
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      })

      this.$store.commit('updateTrackers', {
        pubid: params.pubid,
        pub1: params.pubidsub1,
        pub2: params.pubidsub2,
        pub3: params.pubidsub3,
        pub4: params.pubidsub4,
        pub5: params.pubidsub5,
        pubid_reference: params.pubid_reference,
      })
    } else if (clickCookies) {
      this.$store.commit('updateTrackers', {
        pubid: handledCookies[1],
        pub1: handledCookies[2],
        pub2: handledCookies[3],
        pub3: handledCookies[4],
        pub4: handledCookies[5],
        pub5: handledCookies[6],
        pubid_reference: handledCookies[7],
      })
    } else {
      this.setDefaultValue()
    }
    this.$store.dispatch('setTrackingCookies')

    this.setClickTracker()
  },

  components: {
    AboutUsWidget,
  },
  computed: {
    pubid: {
      get() {
        return this.$store.state.salusTrackers.pubid
      },
      set(pubid) {
        this.$store.state.salusTrackers.pubid = pubid
      },
    },
    pub1: {
      get() {
        return this.$store.state.salusTrackers.pub1
      },
      set(pub1) {
        this.$store.state.salusTrackers.pub1 = pub1
      },
    },
    pub2: {
      get() {
        return this.$store.state.salusTrackers.pub2
      },
      set(pub2) {
        this.$store.state.salusTrackers.pub2 = pub2
      },
    },
    pub3: {
      get() {
        return this.$store.state.salusTrackers.pub3
      },
      set(pub3) {
        this.$store.state.salusTrackers.pub3 = pub3
      },
    },
    pub4: {
      get() {
        return this.$store.state.salusTrackers.pub4
      },
      set(pub4) {
        this.$store.state.salusTrackers.pub4 = pub4
      },
    },
    pub5: {
      get() {
        return this.$store.state.salusTrackers.pub5
      },
      set(pub5) {
        this.$store.state.salusTrackers.pub5 = pub5
      },
    },
    pubid_reference: {
      get() {
        return this.$store.state.salusTrackers.pubid_reference
      },
      set(pubid_reference) {
        this.$store.state.salusTrackers.pubid_reference = pubid_reference
      },
    },
    type() {
      return this.$store.state.applicationType.type
    },
    showFullFooter: function () {
      const path = this.$route.path
      return path === this.$root.rn.MainView
    },
    articles() {
      return this.$store.state.articles.landingHeaders
    },
    salusId() {
      return salusId
    },
  },
  methods: {
    setDefaultValue() {
      if (this.pubid === '' || this.pubid === null || this.pubid === undefined) {
        this.$store.commit('updateTrackers', { pubid: '1547006' })

        Cookies.set('pubid', this.salusId, { expires: '30D' })
      }
    },
    setLoan(value, e) {
      this.loanamount = value
      const key = 'amount'
      this.$store.commit('updateApplication', { key, value })
      this.$router
        .push({
          name: 'DynamicLoanPage',
          params: { loanamount: value },
        })
        .catch(() => {})
      e.preventDefault()
    },
    setClickTracker() {
      this.$store.dispatch('fetchTrackingCookies')
      const script = document.getElementById('salusgroupClickTrack')

      const tracker = salusClickTracker(
        this.pubid,
        this.pub1,
        this.pub2,
        this.pub3,
        this.pub4,
        this.pub5
      )

      script.innerHTML = tracker
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.footer-dk {
  background: #05083c;
}
.white-link {
  margin: 1rem;
}
.white {
  color: white;
}
.nav {
  display: inline;
}
.link-row {
  @media (min-width: 950px) {
    justify-content: flex-end;
  }

  @media (max-width: 890px) {
    justify-content: flex-start;
  }
  @media (max-width: 550px) {
    text-align: center;
    justify-content: center;
  }
}
.customer-service-info {
  @media (max-width: 550px) {
    text-align: center;
  }
}

.shortlist {
  margin-left: 1rem;
  @media (max-width: 890px) {
    margin: auto;
  }
}

.blue-text {
  color: #05083c;
}
</style>
