import _ from 'lodash'
import lang from '@/lang'
import enumActions from '@/store/actions/enumActions'

// Initial state
const state = {
  maritalStatuses: {},
  dwellingOptions: {},
  occupationOptions: {},
  loanPurposeOptions: {},
  residencePermitTypes: {},
  residencePermitSubTypes: {},
  residencePermitSubTypesPermanent: {},
  residencePermitSubTypesTemporary: {},
  cardIssuerOptions: {},
  coApplicantResidencePermitSubTypes: {},
  coApplicantResidencePermitSubTypesTemporary: {},
  coApplicantResidencePermitSubTypesPermanent: {},
  educationOptions: {},
}

const actions = enumActions

const mutations = {
  setEnums(state, enums) {
    state['maritalStatuses'] = buildFormArray(
      filterEnums(enums.maritalStatuses, 'maritalStatuses')
    )
    state['dwellingOptions'] = buildFormArray(
      filterEnums(enums.dwellingOptions, 'dwellingOptions')
    )

    state['occupationOptions'] = buildFormArray(
      filterEnums(enums.occupationOptions, 'occupationOptions')
    )
    state['loanPurposeOptions'] = buildFormArray(
      filterEnums(enums.loanPurposeOptions, 'loanPurposeOptions')
    )

    state['residencePermitTypes'] = buildFormArray(
      filterEnums(enums.residencePermitTypes, 'residencePermitTypes')
    )
    state['residencePermitSubTypes'] = enums.residencePermitSubTypes.dk.all
    state['residencePermitSubTypesPermanent'] = enums.residencePermitSubTypes.dk.permanent

    state['coApplicantResidencePermitSubTypes'] = enums.residencePermitSubTypes.dk.all
    state['coApplicantResidencePermitSubTypesPermanent'] =
      enums.residencePermitSubTypes.dk.permanent
    state['coApplicantResidencePermitSubTypesTemporary'] = buildTextArray(
      filterEnums(
        enums.residencePermitSubTypes.dk.temporary,
        'coApplicantResidencePermitSubTypesTemporary'
      )
    )

    state['residencePermitSubTypesTemporary'] = buildTextArray(
      filterEnums(
        enums.residencePermitSubTypes.dk.temporary,
        'residencePermiSubTypesTemporary'
      )
    )

    state['cardIssuerOptions'] = buildFormArray(
      filterEnums(enums.cardIssuerOptions, 'cardIssuerOptions')
    )
    state['educationOptions'] = buildFormArray(
      filterEnums(enums.educationOptions, 'educationOptions')
    )

    function buildFormArray(responseObject) {
      const formArray = []
      let i = 0
      for (const key in responseObject) {
        formArray[i] = { value: key, text: lang.t(responseObject[key]) }
        i++
      }
      return formArray
    }

    function buildTextArray(responseObject) {
      const textArray = []
      let i = 0
      for (const text in responseObject) {
        textArray[i] = { value: responseObject[text], text: lang.t(responseObject[text]) }
        i++
      }
      return textArray
    }

    function filterEnums(responseObject, type) {
      let allowed = []
      if (type === 'loanPurposeOptions') {
        allowed = [8, 55, 52, 53, 60, 54, 61, 4, 51]
      } else if (type === 'maritalStatuses') {
        allowed = [1, 2, 3, 4, 5, 6]
      } else if (type === 'dwellingOptions') {
        allowed = [11, 2, 3, 6, 14, 7, 15]
      } else if (type === 'occupationOptions') {
        allowed = [0, 1, 2, 4, 5, 6, 7, 8, 9, 13, 16, 17, 18]
      } else if (type === 'residencePermitTypes') {
        allowed = [1, 2, 3, 4]
      } else if (type === 'cardIssuerOptions') {
        allowed = [1, 3, 4, 5, 6]
      } else if (
        type === 'coApplicantResidencePermitSubTypesTemporary' ||
        type === 'residencePermiSubTypesTemporary'
      ) {
        allowed = [0, 2, 3, 4, 5, 7, 8, 9, 10]
      } else if (type === 'educationOptions') {
        allowed = [2, 3, 4, 5, 6, 7]
      }
      const filtered = _.pick(responseObject, allowed)
      return filtered
    }
  },
}

export default {
  state,
  mutations,
  actions,
}
