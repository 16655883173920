<template>
  <div class="container container-partners">
    <div class="safety-container">
      <h3 class="title title-partners">{{ $t('your_safety') }}</h3>
      <p>{{ $t('your_safety_is_important_rly') }}</p>
    </div>
    <div class="glide">
      <div data-glide-el="track" class="glide__track">
        <ul class="glide__slides">
          <li class="glide__slide">
            <b-img
              class="logo"
              src="/static/img/saverium_dk/partner-logos/ikanobank-logo-small.webp"
              alt="Ikanobank logo"
              height="50"
              width="100"
            />
          </li>
          <li class="glide__slide">
            <b-img
              class="logo"
              src="/static/img/saverium_dk/partner-logos/expressbank-logo-sm.png"
              alt="Expressbank logo"
              height="50"
              width="100"
            />
          </li>
          <li class="glide_slide">
            <b-img
              class="logo"
              src="/static/img/saverium_dk/partner-logos/resurs-logo.svg"
              alt="Resurs bank logo"
              height="50"
              width="100"
            />
          </li>
          <li class="glide_slide">
            <b-img
              class="logo"
              src="/static/img/saverium_dk/partner-logos/sweepbank-logo-small.png"
              alt="Sweepbank logo"
              height="50"
              width="100"
            />
          </li>
          <li class="glide_slide">
            <b-img
              class="logo"
              src="/static/img/saverium_dk/partner-logos/modus_finans_logo.svg"
              alt="Modus Finans"
              height="50"
              width="100"
            />
          </li>
          <li class="glide_slide">
            <b-img
              class="logo"
              src="/static/img/saverium_dk/partner-logos/ferratum-logo.svg"
              alt="Ferratum logo"
              height="50"
              width="100"
            />
          </li>
          <li class="glide_slide">
            <b-img
              class="logo"
              src="/static/img/saverium_dk/partner-logos/lånlet-logo-small.png"
              alt="Lånlet logo"
              height="50"
              width="100"
            />
          </li>
          <li class="glide_slide">
            <b-img
              class="logo"
              src="/static/img/saverium_dk/partner-logos/kreditnu-logo-small.png"
              alt="Kredit Nu logo"
              height="50"
              width="100"
            />
          </li>
          <li class="glide_slide">
            <b-img
              class="logo"
              src="/static/img/saverium_dk/partner-logos/fellow-bank-small.png"
              alt="Fellow Bank"
              height="50"
              width="100"
            />
          </li>
          <li class="glide_slide">
            <b-img
              class="logo"
              src="/static/img/saverium_dk/partner-logos/cashper-logo-small.png"
              alt="Cashper"
              height="50"
              width="100"
            />
          </li>
          <li class="glide_slide">
            <b-img
              class="logo"
              src="/static/img/saverium_dk/partner-logos/nordcredit-logo-small.png"
              alt="Nordcredit logo"
              height="50"
              width="100"
            />
          </li>
          <li class="glide_slide">
            <b-img
              class="logo"
              src="/static/img/saverium_dk/partner-logos/unilan-logo-small.webp"
              alt="Unilån logo"
              height="50"
              width="100"
            />
          </li>
          <li class="glide_slide">
            <b-img
              class="logo"
              src="/static/img/saverium_dk/partner-logos/TFB_logo.webp"
              alt="TF Bank logo"
              height="50"
              width="100"
            />
          </li>
          <li class="glide_slide">
            <b-img
              class="logo"
              src="/static/img/saverium_dk/partner-logos/remember-logo.webp"
              alt="Remember logo"
              height="50"
              width="100"
            />
          </li>
        </ul>
      </div>
      <div data-glide-el="controls" class="carousel-control">
        <button class="button start-btn" data-glide-dir="<" aria-label="left">
          <i class="fal fa-chevron-left"></i>
        </button>
        <button class="button end-btn" data-glide-dir=">" aria-label="right">
          <i class="fal fa-chevron-right"></i>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import Glide from '@glidejs/glide'
import { isMobile } from '@/utils/utils.js'
export default {
  mounted() {
    new Glide('.glide', {
      type: 'carousel',
      startAt: 0,
      perView: 10,
      autoplay: 2000,
      breakpoints: {
        1920: {
          perView: 8,
        },
        1200: {
          perView: 6,
        },
        800: {
          perView: 4,
        },
        600: {
          perView: 3,
        },
      },
    }).mount()
  },
  data() {
    return {
      slide: 0,
      sliding: null,
    }
  },

  methods: {
    onSlideStart(slide) {
      this.sliding = true
    },
    onSlideEnd(slide) {
      this.sliding = false
    },
    handleCarouselSizing() {
      return isMobile ? 3 : 5
    },
  },
}
</script>
<style lang="scss" scoped>
.logo {
  margin: auto;
}
.carousel-control {
  display: inline;
}
.glide {
  margin-top: 2rem;
  margin-bottom: 4rem;
  padding-left: 2rem;
  padding-right: 2rem;
}
.end-btn {
  position: absolute;
  right: 0;
  top: 0rem;
  height: 100%;
  background: none;
  border: none;
  color: black;
  background: linear-gradient(270deg, white, rgba(255, 255, 255, 0));
  font-size: 1.5rem;
}
.start-btn {
  position: absolute;
  left: 0;
  top: 0rem;
  height: 100%;
  background: none;
  border: none;
  color: black;
  background: linear-gradient(90deg, white, rgba(255, 255, 255, 0));
  font-size: 1.5rem;
}
.container-partners {
  margin-top: 3rem;
  text-align: center;
  .title-partners {
    margin: 2rem auto;
  }
  .safety-container {
    margin-bottom: 2rem;
    p {
      margin-top: 1rem;
    }
    @media (max-width: 400px) {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }
}
</style>
