const script = (pubid, pub1, pub2, pub3, pub4, pub5) => {
  return `
      salusgroupClickTrack.publisher.pubid = '${pubid ? pubid.toString() : ''}';
      salusgroupClickTrack.publisher.pubidsub1 = '${pub1 ? pub1.toString() : ''}';
      salusgroupClickTrack.publisher.pubidsub2 = '${pub2 ? pub2.toString() : ''}';
      salusgroupClickTrack.publisher.pubidsub3 = '${pub3 ? pub3.toString() : ''}';
      salusgroupClickTrack.publisher.pubidsub4 = '${pub4 ? pub4.toString() : ''}';
      salusgroupClickTrack.publisher.pubidsub5 = '${pub5 ? pub5.toString() : ''}';
      salusgroupClickTrack.countrycode = 'dk'; salusgroupClickTrack.chat = false;
      salusgroupClickTrack.run();
   `
}

export default script
